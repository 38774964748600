var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Bank Soal")])]), _c('b-col', [_c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-sm",
      modifiers: {
        "modal-sm": true
      }
    }],
    staticClass: "btn-icon mr-1",
    attrs: {
      "variant": "outline-warning",
      "to": {
        name: "admin-bank-soal"
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "ListIcon"
    }
  }), _vm._v("Soal")], 1), _vm.allowCreate() ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-sm",
      modifiers: {
        "modal-sm": true
      }
    }],
    staticClass: "btn-icon",
    attrs: {
      "variant": "outline-primary",
      "to": {
        name: "admin-tambah-bank-soal"
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Tambah Soal")], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-upload",
      "centered": "",
      "size": "lg",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.UploadThumbnail($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.ModalUploadThumbnail = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ModalUploadThumbnail,
      callback: function callback($$v) {
        _vm.ModalUploadThumbnail = $$v;
      },
      expression: "ModalUploadThumbnail"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Thumbnail",
      "label-for": "Thumbnail"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "Thumbnail",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.form.thumbnail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "thumbnail", $$v);
      },
      expression: "form.thumbnail"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "centered": "",
      "size": "lg",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.Modal = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Paket",
      "label-for": "nama-paket"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nama Paket",
      "name": "nama-paket"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga",
      "label-for": "harga"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "price"
    },
    on: {
      "keyup": _vm.doFormatRupiah
    },
    model: {
      value: _vm.form.price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Mulai",
      "label-for": "info_start"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      }
    },
    model: {
      value: _vm.form.info_start,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "info_start", $$v);
      },
      expression: "form.info_start"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Akhir",
      "label-for": "info_end"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      }
    },
    model: {
      value: _vm.form.info_end,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "info_end", $$v);
      },
      expression: "form.info_end"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Deskripsi",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "name": "description",
      "placeholder": "Deskripsi"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Maksimal Peserta",
      "label-for": "max_quota"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "max_quota"
    },
    on: {
      "keyup": _vm.doFormatRupiah
    },
    model: {
      value: _vm.form.max_quota,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "max_quota", $$v);
      },
      expression: "form.max_quota"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kategori",
      "label-for": "kategori"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.mapelData
    },
    model: {
      value: _vm.form.category_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "category_id", $$v);
      },
      expression: "form.category_id"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('label', [_vm._v("Pilih Paket")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optPaket
    },
    on: {
      "input": function input($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filterPaket,
      callback: function callback($$v) {
        _vm.filterPaket = $$v;
      },
      expression: "filterPaket"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('label', [_vm._v("Pilih Tipe")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "value",
      "clearable": true,
      "options": _vm.optTipe
    },
    on: {
      "input": function input($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filterTipe,
      callback: function callback($$v) {
        _vm.filterTipe = $$v;
      },
      expression: "filterTipe"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "2",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-30",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';

        _vm.getData();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "",
      "striped": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "fields": _vm.fields,
      "items": _vm.soalData
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(selected)",
      fn: function fn(_ref) {
        var rowSelected = _ref.rowSelected;
        return [rowSelected ? [_c('i', {
          staticClass: "feather icon-disc primary"
        })] : [_c('i', {
          staticClass: "feather icon-circle"
        })]];
      }
    }, {
      key: "cell(show_details)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function callback($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('b-row', {}, [_c('b-col', {
          staticClass: "mb-1",
          attrs: {
            "md": "4"
          }
        }, [_c('strong', [_vm._v("Tipe Soal : ")]), _vm._v(_vm._s(row.item.type) + " ")]), _c('b-col', {
          staticClass: "mb-1",
          attrs: {
            "md": "4"
          }
        }, [_c('strong', [_vm._v("Bobot : ")]), _vm._v(_vm._s(row.item.bobot) + " ")])], 1), _c('b-row', [_c('b-col', {
          staticClass: "mb-1"
        }, [_c('strong', [_vm._v("Pertanyaan : ")]), _c('quill-editor', {
          attrs: {
            "id": row.item.id,
            "options": _vm.editor_options
          },
          model: {
            value: row.item.content,
            callback: function callback($$v) {
              _vm.$set(row.item, "content", $$v);
            },
            expression: "row.item.content"
          }
        })], 1)], 1), _c('b-row', [row.item.type == 'pg' || row.item.type == 'multiple_choice' || row.item.type == 'benar_salah' ? _c('div', [_c('b-tabs', {
          staticClass: "mt-2"
        }, _vm._l(row.item.options, function (data, index) {
          return _c('div', {
            key: index
          }, [_c('b-tab', {
            scopedSlots: _vm._u([{
              key: "title",
              fn: function fn() {
                return [_c('div', [index + 1 == 1 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. E")]) : _vm._e()])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
            attrs: {
              "id": "Konten",
              "options": _vm.editor_options
            },
            model: {
              value: data.content,
              callback: function callback($$v) {
                _vm.$set(data, "content", $$v);
              },
              expression: "data.content"
            }
          })], 1)], 1), _c('b-row', [row.item.type == 'multiple_choice' || row.item.type == 'benar_salah' ? _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [index + 1 == 1 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. E")]) : _vm._e(), row.item.type == 'multiple_choice' ? _c('b-form-checkbox', {
            staticClass: "custom-control-primary",
            attrs: {
              "value": "1"
            },
            model: {
              value: data.is_correct,
              callback: function callback($$v) {
                _vm.$set(data, "is_correct", $$v);
              },
              expression: "data.is_correct"
            }
          }, [_vm._v(" Kunci Jawaban ")]) : _vm._e(), row.item.type == 'benar_salah' ? _c('b-form-radio-group', {
            attrs: {
              "options": _vm.bsSoal,
              "name": "radios-stacked",
              "stacked": ""
            },
            model: {
              value: data.is_true_or_false,
              callback: function callback($$v) {
                _vm.$set(data, "is_true_or_false", $$v);
              },
              expression: "data.is_true_or_false"
            }
          }) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1);
        }), 0)], 1) : _vm._e()]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" Hide Details ")])], 1)];
      }
    }, {
      key: "cell(tgl)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDate(data.item.info_start)) + " s/d " + _vm._s(_vm.humanDate(data.item.info_end)) + " ")];
      }
    }, {
      key: "cell(check)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.select_soal,
            callback: function callback($$v) {
              _vm.select_soal = $$v;
            },
            expression: "select_soal"
          }
        })];
      }
    }, {
      key: "cell(max_quota)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(data.item.max_quota)) + " ")];
      }
    }, {
      key: "cell(color)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": data.item.color
          }
        }, [_vm._v(" " + _vm._s(data.item.color) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.type == "pg" ? "PG" : data.item.type == "multiple_choice" ? "Multiple Choice" : data.item.type == "benar_salah" ? "Benar Salah" : "Pauli") + " ")];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_vm._v(" > "), _c('b-table', {
    attrs: {
      "small": "",
      "responsive": "sm",
      "per-page": _vm.perPage2,
      "current-page": _vm.currentPage2,
      "sort-by": _vm.sortBy2,
      "sort-desc": _vm.sortDesc2,
      "sort-direction": _vm.sortDirection2,
      "filter": _vm.filter2,
      "filter-included-fields": _vm.filterOn2,
      "fields": _vm.fields2,
      "items": _vm.judulsoal
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "filtered": _vm.onFiltered2
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(show_details)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function callback($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('b-row', {}, [_c('b-col', {
          staticClass: "mb-1",
          attrs: {
            "md": "4"
          }
        }, [_c('strong', [_vm._v("Tipe Soal : ")]), _vm._v(_vm._s(row.item.type) + " ")]), _c('b-col', {
          staticClass: "mb-1",
          attrs: {
            "md": "4"
          }
        }, [_c('strong', [_vm._v("Bobot : ")]), _vm._v(_vm._s(row.item.bobot) + " ")])], 1), _c('b-row', [_c('b-col', {
          staticClass: "mb-1"
        }, [_c('strong', [_vm._v("Pertanyaan : ")]), _c('quill-editor', {
          attrs: {
            "id": row.item.id,
            "options": _vm.editor_options
          },
          model: {
            value: row.item.content,
            callback: function callback($$v) {
              _vm.$set(row.item, "content", $$v);
            },
            expression: "row.item.content"
          }
        })], 1)], 1), _c('b-row', [row.item.type == 'pg' || row.item.type == 'multiple_choice' || row.item.type == 'benar_salah' ? _c('div', [_c('b-tabs', {
          staticClass: "mt-2"
        }, _vm._l(row.item.options, function (data, index) {
          return _c('div', {
            key: index
          }, [_c('b-tab', {
            scopedSlots: _vm._u([{
              key: "title",
              fn: function fn() {
                return [_c('div', [index + 1 == 1 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. E")]) : _vm._e()])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
            attrs: {
              "id": "Konten",
              "options": _vm.editor_options
            },
            model: {
              value: data.content,
              callback: function callback($$v) {
                _vm.$set(data, "content", $$v);
              },
              expression: "data.content"
            }
          })], 1)], 1), _c('b-row', [row.item.type == 'multiple_choice' || row.item.type == 'benar_salah' ? _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [index + 1 == 1 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. E")]) : _vm._e(), row.item.type == 'multiple_choice' ? _c('b-form-checkbox', {
            staticClass: "custom-control-primary",
            attrs: {
              "value": "1"
            },
            model: {
              value: data.is_correct,
              callback: function callback($$v) {
                _vm.$set(data, "is_correct", $$v);
              },
              expression: "data.is_correct"
            }
          }, [_vm._v(" Kunci Jawaban ")]) : _vm._e(), row.item.type == 'benar_salah' ? _c('b-form-radio-group', {
            attrs: {
              "options": _vm.bsSoal,
              "name": "radios-stacked",
              "stacked": ""
            },
            model: {
              value: data.is_true_or_false,
              callback: function callback($$v) {
                _vm.$set(data, "is_true_or_false", $$v);
              },
              expression: "data.is_true_or_false"
            }
          }) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1);
        }), 0)], 1) : _vm._e()]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" Hide Details ")])], 1)];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }, {
      key: "cell(tgl)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDate(data.item.info_start)) + " s/d " + _vm._s(_vm.humanDate(data.item.info_end)) + " ")];
      }
    }, {
      key: "cell(max_quota)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(data.item.max_quota)) + " ")];
      }
    }, {
      key: "cell(color)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": data.item.color
          }
        }, [_vm._v(" " + _vm._s(data.item.color) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.type == "pg" ? "PG" : data.item.type == "multiple_choice" ? "Multiple Choice" : data.item.type == "benar_salah" ? "Benar Salah" : "Pauli") + " ")];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows2,
      "per-page": _vm.perPage2,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage2,
      callback: function callback($$v) {
        _vm.currentPage2 = $$v;
      },
      expression: "currentPage2"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-start"
  }, [_c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.update($event);
      }
    }
  }, [_vm._v(" Simpan")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }